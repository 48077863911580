import type { ReactElement } from 'react';

export function FaqPrice(): ReactElement {
	return (
		<div className='flex flex-col items-center justify-center'>
			<div className='max-w-4xl space-y-4'>
				<div className='group rounded-lg bg-gray-50 p-6'>
					<summary className='flex cursor-pointer items-center justify-between'>
						<h5 className='font-medium text-gray-900'>Ile zapłacę za pobyt kociaka?</h5>
					</summary>

					<p className='mt-4 leading-relaxed text-gray-700'>
						W naszym hotelu jest kilka wartiantów cenowych w zależności od ilości kotów i ich rozkładu po pokojach.
						Kotki od jednego właściciela można umieścić bez obaw w jednym pokoju co zaskutuje mniejszą ceną za każdego
						kolejnego pupila. W jednym pokoiku może być maksymalnie do 3 kotów.
						<br />
						<br />
						Przykładowe ceny za dobę w jednym pokoju:
						<br />
						1 kot - 45zł/dobę
						<br />
						2 koty - 73zł/dobę
						<br />3 koty - 85zł/dobę
					</p>
				</div>
				<details className='group rounded-lg bg-gray-50 p-6'>
					<summary className='flex cursor-pointer items-center justify-between'>
						<h5 className='font-medium text-gray-900'>Jak wygląda opłata za pobyt?</h5>

						<span className='relative ml-1.5 h-5 w-5 flex-shrink-0'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='absolute inset-0 opacity-100 group-open:opacity-0'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
								strokeWidth='2'>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
								/>
							</svg>

							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='absolute inset-0 opacity-0 group-open:opacity-100'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
								strokeWidth='2'>
								<path strokeLinecap='round' strokeLinejoin='round' d='M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z' />
							</svg>
						</span>
					</summary>

					<p className='mt-4 leading-relaxed text-gray-700'>
						{' '}
						Płatności za pobyt przyjmowane są z góry. Opłacenie rezerwacji to 50% ceny pobytu - w przypadku rezygnacji
						wpłata nie podlega zwrotowi. Ceny pobytu za pokój nie posiadają żadnych ukrytych płatności. Nr konta do
						opłacenia rezerwacji:
						<br />
						<br />
						Dominika Grabowska CREDIT AGRICOLE <span className='text-indigo-700'>74 1940 1076 5934 7369 0000 0000</span>
					</p>
				</details>
				<details className='group rounded-lg bg-gray-50 p-6'>
					<summary className='flex cursor-pointer items-center justify-between'>
						<h5 className='font-medium text-gray-900'>Co w razie potrzeby wizyty u weterynarza?</h5>

						<span className='relative ml-1.5 h-5 w-5 flex-shrink-0'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='absolute inset-0 opacity-100 group-open:opacity-0'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
								strokeWidth='2'>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
								/>
							</svg>

							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='absolute inset-0 opacity-0 group-open:opacity-100'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
								strokeWidth='2'>
								<path strokeLinecap='round' strokeLinejoin='round' d='M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z' />
							</svg>
						</span>
					</summary>

					<p className='mt-4 leading-relaxed text-gray-700'>
						Jeżeli jest taka potrzeba by kot który jest aktualnie u nas na wczasach, pojechał na wizytę do weterynarza
						właściciel jest zobowiązany opłacić koszty weterynaryjne + należy doliczyć opłatę usługi w wysokości 50zł.
					</p>
				</details>
			</div>
		</div>
	);
}
