import type { ReactElement } from "react";

export function FaqProcedures(): ReactElement {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="max-w-4xl space-y-4">
        <div className="group rounded-lg bg-gray-50 p-6">
          <summary className="flex cursor-pointer items-center justify-between">
            <h5 className="font-medium text-gray-900">
              Czy wszystkie koty przebywają razem?
            </h5>
          </summary>

          <p className="mt-4 leading-relaxed text-gray-700">
            Nie pozwalamy obcym kotom mieć ze sobą kontaktu - co oznacza, że
            wszystkie koty od różnych właścicieli są wypuszczane osobno. Nie
            daje to możliwości sytuacji konfliktów między zwierzakami. Kotki z
            tych samych domów mogą przebywać razem jeżeli właściciel tego
            zechce.
          </p>
        </div>
        <details className="group rounded-lg bg-gray-50 p-6">
          <summary className="flex cursor-pointer items-center justify-between">
            <h5 className="font-medium text-gray-900">
              Co jedzą kotki? Czy każdy dostaje to samo jedzenie?
            </h5>

            <span className="relative ml-1.5 h-5 w-5 flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute inset-0 opacity-100 group-open:opacity-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute inset-0 opacity-0 group-open:opacity-100"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          </summary>

          <p className="mt-4 leading-relaxed text-gray-700">
            Każdy kot ma przechowywaną swoją własną karmę i to ją spożywa. Nie
            karmimy kota jedzeniem którego nie ma na codzień w domu by nie
            spowodować problemów żoładkowych. Każdy właściciel jest zobowiązany
            przywieźć ze sobą przy przyjeździe jedzenie dla kotka które ma mu
            wystarczyć na cały pobyt. Dawki jedzenia uzgadniane są na miejscu.
          </p>
        </details>
        <details className="group rounded-lg bg-gray-50 p-6">
          <summary className="flex cursor-pointer items-center justify-between">
            <h5 className="font-medium text-gray-900">
              Czy koty muszą mieć aktualne szczepienia?
            </h5>

            <span className="relative ml-1.5 h-5 w-5 flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute inset-0 opacity-100 group-open:opacity-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute inset-0 opacity-0 group-open:opacity-100"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          </summary>

          <p className="mt-4 leading-relaxed text-gray-700">
            Tak, jest to wymóg bez którego nie ma możliwości odbycia wizyty w
            naszym hotelu. Kot musi posiadać aktualne szczepienia na choroby
            zakaźne. <span className="text-indigo-700">Uwaga: </span>
            data samego szczepienia kota nie może być krótsza niż 2 tygodnie w
            stosunku do przyjazdu kota.
          </p>
        </details>
      </div>
    </div>
  );
}
