import {
  type Dispatch,
  type ReactElement,
  type SetStateAction,
  useRef,
  useState,
} from "react";
import { faPhone, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import emailjs, { type EmailJSResponseStatus } from "emailjs-com";

type Props = {
  setPopUp: Dispatch<SetStateAction<boolean>>;
  setErrorPopUp: Dispatch<SetStateAction<boolean>>;
};

export function Form({ setPopUp, setErrorPopUp }: Props): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null);

  const PUBLIC_API_KEY = "xo6zRwvL2H6_z362c";
  const SERVICE_ID = "service_ujc5rr8";
  const TEMPLATE_ID = "template_w906w2f";

  const sendEmail = (e: any) => {
    e.preventDefault();
    if (!formRef.current) return;
    setIsLoading(true);
    emailjs.init(PUBLIC_API_KEY);
    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, formRef.current)
      .then((result: EmailJSResponseStatus) => {
        if (result) setPopUp(true);
        console.log(result);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error) setErrorPopUp(true);
        console.log(error);
        setIsLoading(false);
      });
    e.target.reset();
  };

  return (
    <section
      className="mx-auto w-full bg-gray-50 px-4 pt-16 pb-16 sm:px-6 lg:px-8"
      id="contact"
    >
      <div className="mx-auto max-w-lg">
        <h1 className="cursor-default text-center text-2xl font-bold text-indigo-600 sm:text-3xl">
          Dowiedz się więcej{" "}
          <FontAwesomeIcon
            className="h-8 w-8 text-indigo-600"
            icon={faUserGraduate}
          />
        </h1>

        <p className="mx-auto mt-4 max-w-md cursor-default text-center text-gray-500">
          Już dziś napisz do Nas wiadomość, a my postaramy się rozwiać twoje
          wszystkie wątpliwości! Przed wysłaniem zobacz zakładkę info.
        </p>

        <form
          onSubmit={sendEmail}
          ref={formRef}
          className="mt-6 mb-0 space-y-4 rounded-lg p-8 shadow-2xl"
        >
          <p className="cursor-default text-lg font-medium">Wyślij zapytanie</p>

          <div>
            <label htmlFor="email" className="text-sm font-medium">
              E-mail
            </label>

            <div className="relative mt-1">
              <input
                type="email"
                id="email"
                name="email"
                className="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm outline-indigo-600"
                placeholder="Wprowadż e-mail"
                required
              />
            </div>
          </div>

          <div>
            <label htmlFor="name" className="text-sm font-medium">
              Imię
            </label>

            <div className="relative mt-1">
              <input
                type="text"
                id="name"
                name="name"
                className="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm outline-indigo-600"
                placeholder="Wpisz swoje imię..."
                required
              />
            </div>
          </div>

          <div>
            <label htmlFor="title" className="text-sm font-medium">
              Tytuł
            </label>

            <div className="relative mt-1">
              <input
                type="text"
                id="title"
                name="title"
                className="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm outline-indigo-600"
                placeholder="Uzupełnij tytuł..."
                required
              />
            </div>
          </div>

          <div>
            <label htmlFor="message" className="text-sm font-medium">
              Wiadomość
            </label>

            <div className="relative mt-1">
              <textarea
                id="message"
                name="message"
                className="h-40 w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm outline-indigo-600"
                placeholder="Napisz wiadomość..."
                required
              />
            </div>
          </div>

          {!isLoading ? (
            <button
              type="submit"
              className="block w-full rounded border border-indigo-600 bg-indigo-600 px-5 py-3 text-sm font-medium text-white transition-colors hover:bg-indigo-700 focus:outline-none focus:ring active:text-indigo-600"
            >
              <span className="text-sm font-medium">Wyślij</span>
            </button>
          ) : (
            <button className="block w-full rounded border border-indigo-600 bg-indigo-600 px-5 py-3 text-sm font-medium text-white transition-colors hover:bg-indigo-700 focus:outline-none focus:ring active:text-indigo-600">
              <span className="text-sm font-medium">Wysyłanie...</span>
            </button>
          )}
          <div className="flex w-full flex-col items-center justify-center">
            <span className="pt-2 pb-4">lub zadzwoń</span>
            <a
              href="tel:500227824"
              className="text-indigo-700 hover:text-indigo-900"
            >
              <FontAwesomeIcon icon={faPhone} /> 500-227-824
            </a>
          </div>
        </form>
      </div>
    </section>
  );
}
