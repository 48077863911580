import type { ReactElement } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faEllipsisVertical,
  faHotel,
  faMoneyBill1Wave,
} from "@fortawesome/free-solid-svg-icons";
import { FaqHotel } from "./sections/FaqHotel";
import { FaqOthers } from "./sections/FaqOthers";
import { FaqProcedures } from "./sections/FaqProcedures";
import { FaqPrice } from "./sections/FaqPrice";

enum Section {
  HOTEL,
  PRICING,
  PROCEDURES,
  OTHERS,
}

export function Faq(): ReactElement {
  const [activeSection, setActiveSection] = useState(Section.HOTEL);

  const selectedSectionStyles =
    "-mb-px cursor-pointer border-b border-indigo-600 p-4 text-indigo-600";
  const unselectedSectionStyles =
    "-mb-px cursor-pointer border-b border-transparent p-4 hover:text-indigo-600";

  return (
    <section
      id="faq"
      className="flex flex-col items-center justify-center pt-16 pb-4"
    >
      <div className="max-w-4xl space-y-4">
        <nav className="flex border-b border-gray-100 p-6 pt-0 text-sm font-medium">
          <p
            onClick={() => setActiveSection(Section.HOTEL)}
            className={
              activeSection === Section.HOTEL
                ? selectedSectionStyles
                : unselectedSectionStyles
            }
          >
            Hotel{" "}
            <FontAwesomeIcon
              className="hidden sm:inline-block"
              icon={faHotel}
            />
          </p>
          <p
            onClick={() => setActiveSection(Section.PRICING)}
            className={
              activeSection === Section.PRICING
                ? selectedSectionStyles
                : unselectedSectionStyles
            }
          >
            Cennik{" "}
            <FontAwesomeIcon
              className="hidden sm:inline-block"
              icon={faMoneyBill1Wave}
            />
          </p>
          <p
            onClick={() => setActiveSection(Section.PROCEDURES)}
            className={
              activeSection === Section.PROCEDURES
                ? selectedSectionStyles
                : unselectedSectionStyles
            }
          >
            Procedury{" "}
            <FontAwesomeIcon
              className="hidden sm:inline-block"
              icon={faClipboardList}
            />
          </p>
          <p
            onClick={() => setActiveSection(Section.OTHERS)}
            className={
              activeSection === Section.OTHERS
                ? selectedSectionStyles
                : unselectedSectionStyles
            }
          >
            Inne{" "}
            <FontAwesomeIcon
              className="hidden sm:inline-block"
              icon={faEllipsisVertical}
            />
          </p>
        </nav>
        {activeSection === Section.HOTEL && <FaqHotel />}
        {activeSection === Section.PRICING && <FaqPrice />}
        {activeSection === Section.PROCEDURES && <FaqProcedures />}
        {activeSection === Section.OTHERS && <FaqOthers />}
      </div>
    </section>
  );
}
