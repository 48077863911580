import type { ReactElement } from "react";

export function FaqOthers(): ReactElement {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="max-w-4xl space-y-4">
        <div className="group rounded-lg bg-gray-50 p-6">
          <summary className="flex cursor-pointer items-center justify-between">
            <h5 className="font-medium text-gray-900">
              Jak koty reagują na nowe miejsce?
            </h5>
          </summary>

          <p className="mt-4 leading-relaxed text-gray-700">
            Wszystko zależy od charakteru kociaka. Zawsze dokładamy największych
            starań aby wizyta w naszym hotelu była dla futrzaków świetnym
            przeżyciem. Natomiast nie ma tu jednoznacznej odpowiedzi, każdy
            zwierzak jest inny i wymaga indywidualnego podejścia które pozwoli
            mu się przystosować do nowego miejsca.
          </p>
        </div>
        <details className="group rounded-lg bg-gray-50 p-6">
          <summary className="flex cursor-pointer items-center justify-between">
            <h5 className="font-medium text-gray-900">
              Mój kot przyjmuje leki - czy może przyjechać do hotelu?
            </h5>

            <span className="relative ml-1.5 h-5 w-5 flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute inset-0 opacity-100 group-open:opacity-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute inset-0 opacity-0 group-open:opacity-100"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          </summary>

          <p className="mt-4 leading-relaxed text-gray-700">
            Jeżeli kot choruje i istnieje potrzeba podawania leków w
            jakiejkolwiek formie, właściciel musi o tym poinformować przed
            rezerwacją pobytu i podać szczegóły ewentualnego prowadzenia
            leczenia przez hotel.
          </p>
        </details>
      </div>
    </div>
  );
}
