import { Link as ScrollLink } from "react-scroll";
import { type ReactElement, useEffect, useState } from "react";
import heroHorizontal from "../../images/hero-hor.jpg";
import heroVertical from "../../images/hero-ver.jpg";

export function Hero(): ReactElement {
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="relative mx-auto h-screen w-full lg:flex lg:items-center">
      <img
        src={width > 768 ? heroHorizontal : heroVertical}
        alt="Cute cat looking at you."
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
      />
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 flex h-full w-full text-center">
        <div className="mx-auto flex h-full max-w-3xl flex-col items-center justify-center">
          <h1 className="cursor-default text-3xl font-extrabold text-indigo-600 sm:text-5xl">
            Profesjonalna opieka.
          </h1>
          <h1 className="cursor-default text-3xl font-extrabold text-indigo-600 sm:text-5xl">
            Spokojny właściciel.
          </h1>

          <p className="mx-auto mt-4 max-w-xl cursor-default text-white sm:text-xl sm:leading-relaxed">
            Jesteśmy hotelem dla kotów który stawia na komfort futrzaków oraz
            właścicieli ponad wszystko, zapewniamy spokój rodzicom pociech oraz
            najlepsze wakacje dla przyjezdnych.
          </p>

          <div className="mt-8 flex cursor-pointer flex-wrap justify-center gap-4">
            <ScrollLink
              spy
              smooth
              duration={500}
              className="block w-full rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition-colors hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
              to="details"
            >
              Dowiedz się więcej
            </ScrollLink>
          </div>
        </div>
      </div>
    </header>
  );
}
