import {
  faCloudMoon,
  faDroplet,
  faHandshakeAngle,
  faHeartCircleCheck,
  faHouseChimneyUser,
  faMountainSun,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as ScrollLink } from "react-scroll";
import type { ReactElement } from "react";

export function Stats(): ReactElement {
  return (
    <section className="bg-gray-900 text-white" id="details">
      <div className="mx-auto max-w-screen-xl px-4 py-24 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="cursor-default text-3xl font-bold sm:text-4xl">
            Oddaj kota w zaufane ręce
          </h2>

          <p className="mt-4 cursor-default text-gray-300">
            Hotel działa na podstawie kilku wartości które sprawiają, że nasza
            współpraca z klientami i opieka nad zwierzakami przebiega
            bezproblemowo i w jak najprzyjemniejszej atmosferze. Przekonaj się
            sam.
          </p>
        </div>

        <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div className="block cursor-default rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-indigo-600/10  hover:shadow-indigo-600/10">
            <FontAwesomeIcon
              className="h-10 w-10 text-indigo-600"
              icon={faDroplet}
            />

            <h3 className="mt-4 text-xl font-bold text-white">
              Spokój i wygoda
            </h3>

            <p className="mt-1 text-sm text-gray-300">
              Nasze miejsce wypoczunku charakteryzuje się ciszą, pobliską naturą
              oraz kompletnym brakiem klimatu miastowego. Dzięki świetnej
              lokalizacji oraz wystroju hotelu, twoja pociecha będzie mogła
              prawdziwie się zrelaksować na przytulnym łóżku.
            </p>
          </div>

          <div className="block cursor-default rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-indigo-600/10 hover:shadow-indigo-600/10">
            <FontAwesomeIcon
              className="h-10 w-10 text-indigo-600"
              icon={faCloudMoon}
            />

            <h3 className="mt-4 text-xl font-bold text-white">
              Indywidualne podejście
            </h3>

            <p className="mt-1 text-sm text-gray-300">
              Ile kotów tyle charakterów - podejście opiekuna to najważniejsza
              rzecz decydująca o tym jak zwierzak zaklimatyzuje się w nowym
              otoczeniu. Dbamy o to, żeby pociechy czuły, że są w przyjaznym
              miejscu i nie stresowały się nowymi sytuacjami.
            </p>
          </div>

          <div className="block cursor-default rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-indigo-600/10 hover:shadow-indigo-600/10">
            <FontAwesomeIcon
              className="h-10 w-10 text-indigo-600"
              icon={faHandshakeAngle}
            />

            <h3 className="mt-4 text-xl font-bold text-white">
              Służymy pomocą
            </h3>

            <p className="mt-1 text-sm text-gray-300">
              W każdym momencie twojego urlopu czuj się swobodnie by
              skontaktować się znami w każdej sprawie. Rozumiemy, że nawet
              chwilowa rozłąka z najważniejszymi członkami rodziny może być
              ciężka, dlatego zawsze jesteśmy w razie jakichkolwiek pytań!
            </p>
          </div>

          <div className="block cursor-default rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-indigo-600/10 hover:shadow-indigo-600/10">
            <FontAwesomeIcon
              className="h-10 w-10 text-indigo-600"
              icon={faHouseChimneyUser}
            />

            <h3 className="mt-4 text-xl font-bold text-white">
              Rodzinna atmosfera
            </h3>

            <p className="mt-1 text-sm text-gray-300">
              Naszym celem jest to aby każdy kociak, który zawita w naszym
              hotelu czuł się kochany oraz potrzebny, uczucie miłości i zadbania
              to najważniejsze fundamenty udanego pobytu. Dbamy o to, żeby pobyt
              u Nas powodował chęć powrotu kota przy każdej najbliższej okazji.
            </p>
          </div>

          <div className="block cursor-default rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-indigo-600/10 hover:shadow-indigo-600/10">
            <FontAwesomeIcon
              className="h-10 w-10 text-indigo-600"
              icon={faMountainSun}
            />

            <h3 className="mt-4 text-xl font-bold text-white">
              Udany wypoczynek
            </h3>

            <p className="mt-1 text-sm text-gray-300">
              Twoje wakacje będą bezstresowe i w końcu poczujesz ulgę, że kot
              znajduje się w odpowiednim miejscu. Nie jest rzadkim przypadkiem,
              iż pociecha która skorzysta z naszej opieki potrafi mieć lepsze
              wakacje od samego właściciela.
            </p>
          </div>

          <div className="block cursor-default rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-indigo-600/10 hover:shadow-indigo-600/10">
            <FontAwesomeIcon
              className="h-10 w-10 text-indigo-600"
              icon={faHeartCircleCheck}
            />

            <h3 className="mt-4 text-xl font-bold text-white">
              Pasja to miłość
            </h3>

            <p className="mt-1 text-sm text-gray-300">
              Hotel został stworzony w zamysłach o realne potrzeby oraz z powodu
              ogromnej miłości do kotków. Każdego czworonoga dażymy opieką jaką
              sami byśmy chcieli aby nasze kotki dostały podczas pobytu.
            </p>
          </div>
        </div>

        <div className="mt-12 text-center">
          <ScrollLink
            spy
            smooth
            duration={500}
            className="mt-8 inline-flex cursor-pointer items-center rounded border border-indigo-600 bg-indigo-600 px-8 py-3 text-white transition-colors hover:bg-indigo-700 focus:outline-none focus:ring active:text-indigo-600"
            to="contact"
          >
            <span className="text-sm font-medium">Skontaktuj się z nami</span>

            <svg
              className="ml-3 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </ScrollLink>
        </div>
      </div>
    </section>
  );
}
