import { Footer } from "./components/Footer/Footer";
import { Form } from "./components/Form/Form";
import { Hero } from "./components/Hero/Hero";
import { Navbar } from "./components/Navbar/Navbar";
import { Reviews } from "./components/Reviews/Reviews";
import { Stats } from "./components/Stats/Stats";
import type { ReactElement } from "react";
import { useState } from "react";
import { Faq } from "./components/FAQ/Faq";
import { Notification } from "./components/Notification";

export default function App(): ReactElement {
  const [popUp, setPopUp] = useState<boolean>(false);
  const [errorPopUp, setErrorPopUp] = useState<boolean>(false);

  return (
    <main className="min-h-screen bg-gray-50 antialiased">
      <Navbar />
      <Hero />
      <Stats />
      <Reviews />
      <Faq />
      <Form setPopUp={setPopUp} setErrorPopUp={setErrorPopUp} />
      <Footer />
      <Notification popUp={popUp} errorPopUp={errorPopUp} />
    </main>
  );
}
